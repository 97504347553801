<template>
    <a-card :border="false">
        <a-form :labelCol="{span:2}" :wrapperCol="{span:20}" :model="form" :rules="rules" ref="formRef">
            <a-form-item label="公告标题" name="title">
                <a-input v-model:value="form.title" :maxlength="40" placeholder="请输入标题" style="width:590px;"
                    :readonly="$route.query.type=='look'"></a-input>
                <span class="m-l-15 s_c999">{{form.title.length || 0}} / 40</span>
            </a-form-item>
            <a-form-item label="通知对象" class="m-t-10" style="margin-bottom:10px">
                <a-radio-group name="radioGroup" v-model:value="form.send_type" :disabled="$route.query.type=='look'">
                    <a-radio :value="1">全部品牌</a-radio>
                    <a-radio :value="2">指定品牌</a-radio>
                    <a-radio :value="3">部分不可见</a-radio>
                </a-radio-group>
            </a-form-item>
            <a-form-item label="" v-show="form.send_type==2||form.send_type==3" :wrapperCol="{span:20,offset:2}">
                <a-button type="primary" :disabled="$route.query.type=='look'" @click="brandVisible=true">选择</a-button>
                <a-button type="primary" class="m-l-15" ghost @click="showVisible=true">已选</a-button>
            </a-form-item>
            <a-form-item label="通知内容" class="m-t-30" name="content">
                <quill v-model:value="form.content" :content="JSON.parse(JSON.stringify(form.content))" :height="500" />
            </a-form-item>
            <a-form-item :wrapperCol="{span:20,offset:2}" class="m-t-30" v-if="$route.query.type!='look'">
                <a-button type="primary" ghost class="m-r-15" @click="form.status=0;submit()">保存草稿</a-button>
                <a-button type="primary" @click="form.status=1;submit()">发布</a-button>
            </a-form-item>
        </a-form>

        <a-modal v-model:visible="showVisible" :width="1000" :zIndex="20000" title="已选品牌商" centered destroyOnClose :footer="null">
            <a-table :columns='columns' :data-source='confirmList' rowKey='id' bordered :pagination="pagination" @change="changePage">
                <template v-slot:action="{index}" >
                    <a-button type="link" @click="selectDel(index)">删除</a-button>
                </template>
            </a-table>
        </a-modal>
        <!-- 品牌商弹窗 -->
        <a-modal v-model:visible="brandVisible" title="选择品牌商" :width='1200' :zIndex="20000"     destroyOnClose centered :footer="null">
            <selectBrand v-on:select="brandSelect"  :checkout="true" :selectList="confirmList" />
        </a-modal>
    </a-card>
</template>
<script>
    import { noticeAdd, noticeDetail, noticeBrands, noticeEdit } from '@/api/brand.js'
    import { reactive, toRefs, ref, inject, onMounted } from 'vue';
    import { message } from 'ant-design-vue';
    import { $iscode } from "@/utils/app";
    export default {
        setup() {
            const $route = inject("$route");
            const $router = inject("$router");
            const $Modal = inject("$Modal");
            const form = ref({
                title: '',
                send_type: 1,
                content: '',
                send: [],
                status:0
            })
            const state = reactive({
                confirmList:[],
                rules: {
                    title: [{ required: true, message: '请输入公告标题!', }],
                    content: [{ required: true, message: '请输入通知内容!', }]
                },
                brandVisible:false,
                showVisible: false,
                pagination: {
                    total: 0,
                    current: 1,
                    pageSize: 5,
                    defaultPageSize: 5,
                    showSizeChanger: true,
                    pageSizeOptions: ['5', '10', '15', '20', '25'],
                    showQuickJumper: true,
                    showTotal: (total) => `共 ${total} 条`,
                },
                columns: [
                    {
                        title: "品牌商编号",
                        dataIndex: "brand_no",
                        customRender: function ({ record }) {
                            return record.brand_no || record.brand_id
                        }
                    },
                    {
                        title: "公司名称",
                        dataIndex: "company",
                    },
                    {
                        title: "品牌商账号",
                        dataIndex: "account",
                    },
                    {
                        title: "操作",
                        dataIndex: "action",
                        width: 120,
                        slots: { customRender: 'action' }
                    }
                ]
            })
            const formRef = ref(null)
            const methods = {
                submit() {
                    formRef.value.validate().then(() => {
                        if (!form.value.content || form.value.content == '<p><br/></p>') return message.error('请输入通知内容！')
                        $Modal.confirm({
                            title: '提示',
                            content: form.value.status==1?'发布后无法撤回修改，请确认发布对象及发布内容':'确定保存草稿？',
                            zIndex: 1000000,
                            centered:true,
                            onOk: async function () {
                                const hide = message.loading('加载中...')
                                let res;
                                if (state.confirmList.length != 0) {
                                    form.value.send = state.confirmList.map(item => {
                                        return item.brand_no
                                    })
                                }
                                if ($route.query.type == 'add') {
                                    res = await noticeAdd({ ...form.value }).then(res => res.data).catch(error => error)
                                }else{
                                    res = await noticeEdit({ ...form.value,id: $route.query.id }).then(res => res.data).catch(error => error)
                                }
                                setTimeout(hide)
                                if ($iscode(res, true)) {
                                    setTimeout(() => {
                                        $router.go(-1)
                                    }, 1000)
                                }
                            }
                        })
                    }).catch(err => {
                        message.error('完善表单!')
                    })
                },
                changePage(e) {
                    state.pagination.current = e.current;
                    state.pagination.pageSize = e.pageSize;
                },
                async getNoticeDetail() {
                    const hide = message.loading('正在加载中...');
                    let res = await noticeDetail({ id: $route.query.id }).then(res => res.data).catch(error => error)
                    setTimeout(hide)
                    if ($iscode(res)) {
                        form.value = res.data || {};
                        form.value.send = Array.isArray(res.data) ? res.data : [];
                    } else {
                        message.error(res.msg)
                    }
                }
            }
            const brandSelect = (e) => {
                state.confirmList = e;
                state.brandVisible = false;
                state.pagination.total = state.confirmList.length;
            };
            const selectDel = (index) =>{
                state.confirmList.splice(index, 1);
                state.pagination.total = state.confirmList.length;
            }   
            const getNoticeBrand = async() => {
                let res = await noticeBrands({ id: $route.query.id }).then(res => res.data).catch(error => error)
                if ($iscode(res)) {
                    state.confirmList = res.data || [];
                    state.pagination.total = state.confirmList.length || 0;
                } else {
                    message.error(res.msg)
                }
            }
            onMounted(() => {
                if ($route.query.type == 'look') {
                    state.columns.splice(3, 1);
                    methods.getNoticeDetail();
                    getNoticeBrand();
                } else {
                    if ($route.query.type == 'edit') {
                        methods.getNoticeDetail();
                        getNoticeBrand();
                    }
                    state.columns.splice(3, 1, {
                        title: "操作",
                        dataIndex: "action",
                        width: 120,
                        slots: { customRender: 'action' }
                    })
                }
            })


            return {
                ...toRefs(state),
                ...methods,
                form,
                formRef,
                brandSelect,
                getNoticeBrand,
                selectDel
            }
        },
    }
</script>